<script lang="ts">
	export let value: string | null;
	export let categoryUrl: string = "";
	export let categoryTitle: string = "";

	export var inHeader: boolean = false;

	$: placeholder = categoryTitle
		? "Search study sets on " + categoryTitle
		: "Search resources";
</script>

<form class="form" action={"/categories/" + categoryUrl} method="get">
	<input
		class={inHeader ? "inHeader" : ""}
		type="text"
		placeholder="&#x1F50E; {placeholder}"
		name="question"
		id="question"
		value={value || ""}
	/>
</form>

<style>
	.form {
		max-width: var(--mid-bound);
	}
	input {
		width: 100%;
		padding: 0.5rem;
		border: 1px solid var(--bg-light);
		border-radius: 0.25rem;
	}

	input:focus-visible {
		outline: 3px solid var(--brand-color);
	}

	.inHeader {
		border: none;
		background-color: var(--bg-light);
		margin-top: 0.3rem;
	}
	form {
		width: 100%;
	}
</style>
